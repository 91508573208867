import React, { useEffect, useRef, useState } from "react";
import KeyTermsOutput from "./KeyTermsOutput";
import QualityIssuesOutput from "./QualityIssuesOutput";
import ContextualGlossaryOutput from "./ContextualGlossaryOutput";
import QualityDifficultyOutput from "components/QualityTools/Qualitydifficult";
import OutputHeader from "./OutputHeader";
import StyleGuideOutput from "./StyleGuideOutput";
import { Tooltip } from "react-tooltip";
import { useLocation } from "@reach/router";
import TextOutput from "./TextOutput";

const PreTranslationOutputs = ({ result,wordCount }) => {
  
  const [tooltipContent, setTooltipContent] = useState('Copy');
  const geToolOutput = (toolName, dataList) => {
    return dataList.find((item) => item.meta.tool_name === toolName)?.result;
  };
console.log(wordCount,"wordcount")
  const [seeMore, setSeeMore] = useState(false);

  const SourceStyleGuideSummary = {
    BrandVoice: "Professional, User-Centric, Efficient",
    Voice: "Engaging, Authoritative",
    ToneOfVoice: "Informative, Encouraging",
    Register: "Accessible, Direct",
    Style: "Clear, Concise, Expository",
    Structure: "Organized, User-friendly, Comprehensive",
  };


  const styleGuide = Object.entries(SourceStyleGuideSummary).forEach(
    ([key, value]) => {
      console.log(`${key}: ${value}`);
    }
  );
  const location = useLocation();
 
  const url = location.href;
 

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    setTooltipContent('Link copied');
    
    
    setTimeout(() => {
      setTooltipContent('Copy');
    }, 2000);
  };
  return (
    <div className="w-full max-w-7xl mx-auto px-4 font-opensans pb-12">
      <div className="flex justify-between items-center pb-3 border-b border-[#C7C7C7]">
        <p className="text-lg text-gray26 font-bold font-opensans">
          Insights & Reports
        </p>
        
        <button
          className="flex items-center gap-2"
          data-tooltip-id="linkcopy-tooltip"
          data-tooltip-content={tooltipContent}
          onClick={copyUrl}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.56674 1.73807C9.39397 0.939105 10.5019 0.497008 11.6519 0.507002C12.802 0.516995 13.9021 0.978279 14.7153 1.7915C15.5285 2.60472 15.9898 3.70482 15.9998 4.85484C16.0098 6.00487 15.5677 7.11282 14.7687 7.94005L14.7598 7.94911L12.5668 10.1421C12.1222 10.5869 11.5871 10.9309 10.9978 11.1507C10.4085 11.3705 9.77884 11.461 9.1515 11.4161C8.52416 11.3712 7.91382 11.1918 7.36188 10.8903C6.80993 10.5887 6.3293 10.172 5.95257 9.66837C5.71075 9.34508 5.77679 8.88696 6.10008 8.64513C6.42338 8.40331 6.8815 8.46935 7.12332 8.79264C7.37447 9.1284 7.6949 9.40622 8.06286 9.60726C8.43082 9.80829 8.83772 9.92784 9.25594 9.95779C9.67417 9.98774 10.0939 9.9274 10.4868 9.78085C10.8797 9.63431 11.2364 9.40499 11.5328 9.10844L11.5329 9.10835L13.7213 6.92003C14.2513 6.36901 14.5444 5.63223 14.5378 4.86755C14.5311 4.10086 14.2236 3.36747 13.6815 2.82532C13.1393 2.28317 12.4059 1.97565 11.6392 1.96899C10.8742 1.96234 10.1371 2.25577 9.58604 2.78623L8.33263 4.03234C8.04632 4.31699 7.58347 4.31564 7.29882 4.02933C7.01417 3.74302 7.01552 3.28016 7.30183 2.99552L8.55919 1.74547L8.56674 1.73807Z"
              fill="#5B93FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.00216 5.84937C5.59144 5.62955 6.22111 5.53903 6.84845 5.58396C7.47579 5.62889 8.08613 5.80821 8.63807 6.10976C9.19002 6.41131 9.67065 6.82804 10.0474 7.33168C10.2892 7.65498 10.2232 8.1131 9.89987 8.35492C9.57657 8.59675 9.11845 8.53071 8.87663 8.20741C8.62548 7.87165 8.30505 7.59383 7.93709 7.3928C7.56913 7.19177 7.16223 7.07222 6.74401 7.04226C6.32578 7.01231 5.906 7.07266 5.51315 7.2192C5.12029 7.36575 4.76355 7.59507 4.46711 7.89161L2.2787 10.08C1.74868 10.631 1.45551 11.3678 1.46215 12.1325C1.46881 12.8992 1.77634 13.6326 2.31848 14.1747C2.86063 14.7169 3.59403 15.0244 4.36071 15.0311C5.12539 15.0377 5.86216 14.7445 6.41318 14.2145L7.6585 12.9692C7.94398 12.6837 8.40683 12.6837 8.69232 12.9692C8.9778 13.2547 8.9778 13.7176 8.69232 14.003L7.44227 15.2531L7.43321 15.262C6.60598 16.061 5.49803 16.503 4.34801 16.4931C3.19798 16.4831 2.09789 16.0218 1.28467 15.2086C0.471443 14.3953 0.0101592 13.2952 0.000165807 12.1452C-0.00982761 10.9952 0.432269 9.88724 1.23124 9.06001L1.24014 9.05095L3.43311 6.85798C3.43308 6.85801 3.43314 6.85795 3.43311 6.85798C3.87774 6.41321 4.41292 6.06917 5.00216 5.84937Z"
              fill="#5B93FF"
            />
          </svg>
          <span className="text-sm font-opensans text-[#5B93FF] font-semibold">
            Copy link
          </span>
        </button>
  
      </div>
      <Tooltip
          id="linkcopy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      {/* Language, Key-terms and Quality Outputs */}
      <div
        // className={`grid ${
        //   geToolOutput("language_detector", result) ||
        //   (geToolOutput("identifyTopic", result) &&
        //     geToolOutput("source_text_checker", result))
        //     ? "lg:grid-cols-2"
        //     : "lg:grid-cols-1"
        // }  mt-6 gap-5`}
        className="flex flex-col gap-5"
      >
        {/* {geToolOutput("language_detector", result) || !geToolOutput("identifyTopic", result) && ( */}
        <div
          className={`${
            geToolOutput("language_detector", result) ||
            geToolOutput("identifyTopic", result)
              ? "block"
              : "hidden"
          }`}
        >
          <div>
            <div className="shadow-box p-5 rounded-lg border border-lightBlue h-full">
              {geToolOutput("language_detector", result) && (
                <div className="flex justify-between items-center">
                  <p className="text-[#6E6E6E] font-semibold">
                    Language detected:
                  </p>
                  <span className="text-lg font-bold text-[#27862A]">
                    {
                      geToolOutput("language_detector", result)
                        ?.detected_languages[0]?.language
                    }
                  </span>
                </div>
              )}
              {geToolOutput("identifyTopic", result) && (
                <hr className="my-3 border border-[#C7C7C7]" />
              )}
              {geToolOutput("identifyTopic", result) && (
                <div className="flex justify-between items-center">
                  <p className="text-[#6E6E6E] font-semibold">Domain:</p>
                  <span className="text-lg font-bold text-[#27862A]">
                    {geToolOutput("identifyTopic", result)?.domain} /{" "}
                    {geToolOutput("identifyTopic", result)?.specialization}
                    {/* {
                geToolOutput("identifyTopic", result)
              } */}
                  </span>
                </div>
              )}
            </div>
            {/* <KeyTermsOutput /> */}
          </div>
          {wordCount >= 250 && (
            <>
          {geToolOutput("summarizer", result) && (
            <TextOutput
              header="Quality Issues Identified"
              textoutput={geToolOutput("summarizer", result)}
            />
          )}
          </>
        )}
          {geToolOutput("source_text_checker", result) && (
            <QualityIssuesOutput
              ispretranslationterm={true}
              header="Quality Issues Identified"
              output={geToolOutput("source_text_checker", result)}
            />
          )}
        </div>
        {/* )} */}
        {geToolOutput("source_text_checker", result) && (
          <QualityIssuesOutput
            ispretranslation={true}
            header="Quality Issues Identified"
            output={geToolOutput("source_text_checker", result)}
          />
        )}
      </div>
      {geToolOutput("contextual_glossary_generator", result) && (
        <ContextualGlossaryOutput
          analysisResult={geToolOutput("contextual_glossary_generator", result)}
          showDownload={false}
        />
      )}

      {/* {seeMore && ( */}
      <div
        // className={`grid  ${
        //   geToolOutput("translation_difficulty_checker", result) &&
        //   geToolOutput("style_guide_generator", result)
        //     ? "lg:grid-cols-2"
        //     : "lg:grid-cols-1"
        // } mt-5 gap-5 ease-in-out duration-300`}
         className="flex flex-col gap-5 mt-5"
      >
        {geToolOutput("translation_difficulty_checker", result) && (
          <div className="shadow-box p-5 rounded-lg  border border-lightBlue">
            <OutputHeader
              title="Text Complexity Assessment"
              tooltiptext="Evaluates the complexity of the source text to determine the most suitable translation method."
              output={geToolOutput("translation_difficulty_checker", result)}
            />
            <QualityDifficultyOutput
              analysisResult={geToolOutput(
                "translation_difficulty_checker",
                result
              )}
              showDownload={false}
            />
          </div>
        )}
        {geToolOutput("style_guide_generator", result) && (
          <StyleGuideOutput
            header="Stylistic Instructions for Translators"
            tooltiptext="Provides stylistic guidelines to ensure the translated text matches the intended voice, tone, and purpose of the original content."
            styleOutput={geToolOutput("style_guide_generator", result)}
          />
        )}
      </div>
      
    </div>
  );
};

export default PreTranslationOutputs;
