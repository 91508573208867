import React from "react";
import OutputHeader from "./OutputHeader";

const KeyTermsOutput = () => {
  const handleCopy = () => {
    const text = document.getElementById("key-terms-output").innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };
  return (
    <div className="shadow-box p-5 rounded-lg mt-3 border border-lightBlue">
      <OutputHeader
        title="Key Terms for Your Attention"
        copyText={handleCopy}
      />

      <div id="key-terms-output">
        <div className="flex text-[12px] font-opensans font-semibold text-[#575757]">
          <div className="flex-[0.4] p-3 bg-[#BDD3FF]">Key Terms</div>
          <div className="flex-1 p-3 bg-[#E0EBFF]">Considerations</div>
        </div>
        <div className="mt-2">
          <div className="flex text-sm font-opensans text-[#424242]">
            <div className="flex-[0.4] p-3 bg-[#E0EBFF] font-bold">emerald</div>
            <p className="flex-1 p-3 bg-[#F0F5FF]">
              Color name 'emerald' may be used as a descriptive term that does
              not require translation for consistency with artistic and poetic
              imagery.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyTermsOutput;
