import React from "react";
import OutputHeader from "./OutputHeader";
import * as XLSX from "xlsx";

const ContextualGlossaryOutput = ({ analysisResult, showDownload }) => {
  const handleCopy = () => {
    const text = document.getElementById("contextual-output").innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };

  const glossaryEntries = analysisResult
    ? Object.entries(analysisResult).map(([term, definition]) => ({
        Term: term,
        Definition: definition,
      }))
    : [];

    const handleDownloadExcel = () => {

      const data = glossaryEntries.map((item) => ({
        Term: item.Term,
        Definition: item.Definition,
      }));
    
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data); 
      XLSX.utils.book_append_sheet(wb, ws, "Glossary"); 
    
      XLSX.writeFile(wb, "key_terms_glossary.xlsx");
    };

  return (
    <div className="shadow-box p-5 rounded-lg mt-5 border border-lightBlue">
      <OutputHeader title="Key Terms Glossary" downloadData={handleDownloadExcel}  tooltiptext="A glossary of important terms from your text and their meanings." copyText={handleCopy} />

      <div id="contextual-output" className="grid  gap-y-1 gap-x-3">
        {glossaryEntries.map((item, index) => (
          <li className="flex" key={index}>
            <p className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
              {item.Term}
            </p>
            <p className="p-3 bg-[#F0F5FF] w-full border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
              {item.Definition}
            </p>
          </li>
        ))}
      </div>
    </div>
  );
};

export default ContextualGlossaryOutput;
