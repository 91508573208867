import React from "react";
import { useState, useRef, useEffect } from "react";
import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import PreTranslationOutputs from "./PreTranslationOutputs";
import Loader from "components/loader";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import UploadIcon from "assets/uploadIcon.svg";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import { fileExtension } from "utils/fileextension";

const PreTranslation = () => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [seeMoreText, setSeeMoreText] = useState(false);
  const textRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const location = useLocation();
  const [queryParams, setQUeryParams] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [result, setResult] = useState(null);
  const responses = [];
  const [apiCall, setApiCall] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [textLoader,setTextLoader] = useState(false)
  const apiEndpoints = [
    "language_detector",
    "identifyTopic",
    "translation_difficulty_checker",
    "contextual_glossary_generator",
    "source_text_checker",
    "style_guide_generator",
    "summarizer",
  ];
  const generateShareId = async () => {
    const { share_id } = await http().get("/api/v2/generate-share-id");
    setShareId(share_id);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("share-id");
    if (!query) {
      generateShareId();
      return;
    }
    setShareId(query);
    const fetchData = async () => {
      const res = await http().get(`${endpoints.forms.aiTools}/${query}`);

      setResult(res);
      setText(res[0]?.meta?.user_text);
    };
    fetchData();
  }, []);



  const handleTextarea = (e) => {
    let newText = e.target.value;
    const words = newText
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // if (words.length > 1000) {
    //   newText = words.slice(0, 1000).join(" ");
    // }

    setText(newText);
  };
  useEffect(() => {
    const wordLength = (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [text]);
  const getOutputs = async () => {
    setApiCall((preVal) => preVal + 1);
    const filteredApiEndpoints = wordCount < 250
    ? apiEndpoints.filter((tool_name) => tool_name !== "summarizer")
    : apiEndpoints;
    try {
      await Promise.all(
        filteredApiEndpoints.map(async (tool_name) => {
          try {
            const res = await http().post(endpoints.forms.aiTools, {
              tool_name,
              user_text: text,
              share_id: shareId,
            });
            responses.push(res);
          } catch (error) {
            console.error(`Failed API call for tool_name: ${tool_name}`, error);
            setError(error);
          } finally {
            setError(false);
          }
        })
      );
    } catch (error) {
      console.error("Error during API calls:", error);
    } finally {
      setLoader(false);
    }
  };

  const generateReport = async () => {
    setLoader(true);
    if (apiCall < 3 && responses?.length < 6) {
      await getOutputs();
    }
    // console.log("Successful API responses:", responses);

    setResult(responses);
   navigate(`/tools/pre-translation?share-id=${shareId}`)
    // window.history.pushState(
    //   {},
    //   "",
    //   `/tools/pre-translation?share-id=${shareId}`
    // );
  };

  useEffect(() => {
    if (result && shareId) {
      setQUeryParams(true);
    }
  }, [result, shareId]);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(textRef.current).lineHeight
        );
        const maxLinesHeight = lineHeight * 3;

        if (textRef.current.scrollHeight > maxLinesHeight) {
          setIsTextOverflowing(true);
        } else {
          setIsTextOverflowing(false);
        }
      }
    };
    checkTextOverflow();
  }, [text, queryParams]);
  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    // if (extension) return;
    // setFile(selectedFile);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      // alert.error(translatedData?.toast_text);
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    // console.log(formData.get("file"), "Uploaded file");

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const getSampleText = () => {
    setTextLoader(true);

    setTimeout(() => {
      const sample = `Subject: Weekly Team Update

Dear Team,

I hope this message finds you well. As we approach the end of the quarter, I wanted to share some key updates and reminders. First, please ensure that all project timelines are adhered to, as we aim to meet our deliverables by the end of next week. Additionally, the performance metrics from last month indicate a 15% increase in productivity, which is commendable. Let's continue to build on this momentum.

I would also like to remind everyone about the upcoming team meeting scheduled for Thursday at 10 AM. Please come prepared with your progress reports and any challenges you may be facing.

Lastly, if you have any questions or need assistance, do not hesitate to reach out. Your collaboration and hard work are greatly appreciated.

Best regards,

[Your Name]  
[Your Position]  
[Your Company]`;

      setText(sample);
      setTextLoader(false);
    }, 1000);
  };

  return (
    <div className="bg-[#F5F5F5]">
      <div className="pt-7">
        <ToolsBreadcrumb />
      </div>
      <div
        className={`max-w-7xl mx-auto px-4 py-12 lg:py-24  ${
          !queryParams && "grid"
        } lg:grid-cols-2 gap-12`}
      >
        {result && result?.length > 0 ? (
          <>
            <p className="text-[#424242] font-bold text-lg font-opensans">
              Source text
            </p>
            <hr className="border-[#C7C7C7] border mt-3 mb-4" />
            <div className="shadow-box relative p-3 rounded-lg">
              <div className="flex gap-3">
                <p
                  ref={textRef}
                  className={`tool pr-5 text-lg font-sans text-[#575757] ${
                    !seeMoreText && " line-clamp-3"
                  }`}
                >
                  {text}{" "}
                </p>
                <svg
                  onClick={() => {
                    setText("");
                    setQUeryParams(false);
                    navigate("/tools/pre-translation");
                    setWordCount(0);
                    setResult(null);
                    setShareId(null);
                    generateShareId();
                  }}
                  className="absolute top-4 right-3 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                    fill="#69AAE8"
                  />
                </svg>
              </div>
              {isTextOverflowing && (
                <div className="mt-4">
                  <button
                    onClick={() => setSeeMoreText(!seeMoreText)}
                    className="mx-auto max-w-max flex items-center text-[#5B93FF] font-semibold font-opensans"
                  >
                    {!seeMoreText ? "See more" : "See less"}
                    <svg
                      className={`ml-2.5 ${
                        seeMoreText && "rotate-180"
                      } ease-in-out duration-150`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.5 1.0625L4.74593 4.34765C4.68069 4.40471 4.59222 4.43679 4.5 4.43679C4.40778 4.43679 4.31931 4.40471 4.25407 4.34765L0.5 1.0625"
                        stroke="#5B93FF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 4.56323L4.74593 7.84839C4.68069 7.90545 4.59222 7.93753 4.5 7.93753C4.40778 7.93753 4.31931 7.90545 4.25407 7.84839L0.5 4.56323"
                        stroke="#5B93FF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
                Pre-Translation Toolkit
              </h1>
              <h2 className="text-[#0A2641] text-center md:text-start font-bold text-[28px] lg:text-[32px] leading-[28px] lg:leading-[38px] font-primary">
                Avoid Issues and Improve Accuracy with Pre-Translation Tools
              </h2>
              <p className="text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4 md:block hidden">
                The Tomedes Pre-Translation Dashboard is a one-stop solution for
                translators and businesses, providing a unified report from
                multiple pre-translation tools. It combines insights from the
                Topic Detection Tool, Contextual Glossary, Source Text Checker,
                and more to prepare your content for accurate, culturally
                nuanced, and consistent translations.
              </p>
            </div>
            <div className="flex flex-col lg:items-center gap-3">
              <div className="relative shadow-box rounded-lg bg-white p-3">
                <div className="flex gap-3 lg:w-[576px] min-h-[268px] w-full">
                  {fileUploadLoading ? (
                    <FileLoadingInput file={file} />
                  ) : sizeLimitError && !fileUploadLoading ? (
                    <ReUploadFile
                      file={file}
                      setFile={setFile}
                      setSizeLImitError={setSizeLImitError}
                      uploadFile={uploadFile}
                    />
                  ) : uploadError && !fileUploadLoading ? (
                    <UnsupportedFile
                      file={file}
                      setSizeLImitError={setSizeLImitError}
                      setFile={setFile}
                      uploadFile={uploadFile}
                      setFileUploadError={setUploadError}
                    />
                  ) : (
                    <>
                      <textarea
                        value={text}
                        placeholder={textLoader ? "" : "Type your text here or"}
                        name="message"
                        id="message"
                        className="tool w-full placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block "
                        onChange={(e) => handleTextarea(e)}
                      />
                      {textLoader && (
                        <div className="absolute top-[11px] left-[14px] text-[#B4B4B4] italic">
                          Adding sample text...
                        </div>
                      )}
                      {!text && !textLoader && (
                        <button
                          className="absolute top-[9px] left-[178px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                          onClick={getSampleText}
                        >
                          Try Sample Text
                        </button>
                      )}
                    </>
                  )}
                  {text && (
                    <svg
                      onClick={() => setText("")}
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                        fill="#69AAE8"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex justify-between items-center mt-1.5">
                  <div className="flex justify-between items-center py-4">
                    {text ? (
                      <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                        {wordCount} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor={`uploadFile `}
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span>Upload File</span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id={`uploadFile `}
                          onChange={uploadFile}
                        />
                      </>
                    )}
                  </div>
                  <button
                    disabled={!text}
                    onClick={generateReport}
                    type="submit"
                    className={` ${
                      !text
                        ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                        : "bg-lightBlue text-white"
                    } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-3 w-[175px]`}
                  >
                    {loader ? <Loader /> : "Generate report"}
                  </button>
                </div>
              </div>
              <p className="text-[#6E6E6E] font-opensans text-[12px] text-center">
                Assisted by AI tools. Inaccuracies may occur.
              </p>
              <p className="text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] mt-[18px] md:hidden block">
              The Tomedes Pre-Translation Dashboard is a one-stop solution for
                translators and businesses, providing a unified report from
                multiple pre-translation tools. It combines insights from the
                Topic Detection Tool, Contextual Glossary, Source Text Checker,
                and more to prepare your content for accurate, culturally
                nuanced, and consistent translations.
              </p>
            </div>
          </>
        )}
      </div>
      {/* Outputs */}
      {result && result?.length > 0 && (
        <PreTranslationOutputs result={result} wordCount={wordCount} />
      )}
    </div>
  );
};

export default PreTranslation;
